<template>
  <div class="transportation">
    <h3 class="sub-title">Transportation</h3>
    <div class="container">
      <h4>LOCATION</h4>
      <p>
        Located in  the riverside section of Middle Ring, Hanyang District, Sixin New City, Wuhan, InterContinental Wuhan is an integral part of Wuhan International Expo Center. 
      </p>
      <div class="img-box">
        <img src="https://drvoicedev.oss-cn-beijing.aliyuncs.com/drvoice/server/uploadfile/2023/09/01/f241b1a516c2472fa801923947f15442.png" alt="">
      </div>
      <h4>Metro Line 6</h4>
      <p>
        It takes 8 minutes by walking from Expo Center North station to hotel.
      </p>
      <div class="img-box">
        <img src="https://drvoicedev.oss-cn-beijing.aliyuncs.com/drvoice/server/uploadfile/2023/09/01/db283101381f458e8b7ce304e8a937d5.png" alt="">
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="less" scoped>
.transportation {
  .sub-title {
    font-weight: 900;
    font-size: 27px;
    line-height: normal;
    margin: 30px 0px 20px 0px;
    padding-bottom: 10px;
    border-bottom: solid 1px #ddd;
  }
  .container {
    font-family: "PublicSans Regular";
    font-size: 16px;
    .title {
      background: linear-gradient(73deg, rgba(63,136,163,1) 30%, rgba(88,46,104,1) 100%);
      text-align: center;
      color: #fff;
      font-size: 24px;
      margin-bottom: 20px;
      font-family: "Akrobat Black";
      h3 {
        padding: 8px;
        color: #fff;
      }
    }
    h4 {
      font-family: "Akrobat Black";
      font-weight: 700;
      position: relative;
      margin: 15px 0 15px;
      padding-left: 15px;
      border-left: 3px solid #e8bd55;
      font-size: 20px;
      line-height: 28px;
    }
    p {
      margin-bottom: 20px;
    }
    .img-box {
      margin: 0 auto;
      max-width: 600px;
      width: 80%;
      padding: 4px;
      background-color: #fff;
      border: 1px solid #dee2e6;
      border-radius: 4px;
      margin-bottom: 20px;
      img {
        max-width: 100%;
      }
    }
  }
}
</style>
